import { useEffect, useState } from "react";
import {
  IPurchasedTicketWithCount,
  IPurchasedTicket,
} from "../../../../helpers/models/txticket_api.model";
import moment from "moment";

interface OrderItemProps {
  isLastPurchasedTicket?: boolean;
  currentTickets: IPurchasedTicket[] | undefined;
  purchasedTicket: IPurchasedTicketWithCount;
  handleTicketClicked: (eachPurchasedTicket: IPurchasedTicket[]) => void;
}

const OrderItem = ({
  purchasedTicket,
  handleTicketClicked,
  currentTickets,
  isLastPurchasedTicket,
}: OrderItemProps) => {
  const [hoverState, setHoveredState] = useState<IPurchasedTicket>();
  const [state, setState] = useState<IPurchasedTicket>();

  const onClick = () => {
    setState((prev) => (prev ? undefined : purchasedTicket));

    const { tinception, stid1, stid2, pv_reservation_class } = purchasedTicket;
    const key = tinception + stid1 + stid2 + pv_reservation_class;
    const hasTicket = currentTickets?.find(
      (ct) =>
        ct.tinception + ct.stid1 + ct.stid2 + ct.pv_reservation_class === key
    );
    if (hasTicket && currentTickets) {
      const tickets = currentTickets?.filter(
        (tl) =>
          tl.tinception + tl.stid1 + tl.stid2 + tl.pv_reservation_class !== key
      );
      setHoveredState(undefined);
      return handleTicketClicked(tickets);
    }
    const { count, ...ticket } = purchasedTicket;
    console.log("count", count);
    handleTicketClicked([ticket]);
    // handleTicketClicked([...(currentTickets || []), ticket]);
  };

  const onBlur = () => {
    setState(undefined);
    setHoveredState(undefined);
  };

  const onMouseEnter = () => {
    setHoveredState(purchasedTicket);
  };

  const onMouseLeave = () => {
    setHoveredState(undefined);
  };

  const isHoverState = () => {
    // if (hoverState && !isLastPurchasedTicket) {
    //   return hoverState.tno === purchasedTicket.tno;
    // }
    if (hoverState) {
      return hoverState.tno === purchasedTicket.tno;
    }
  };

  const isFocusState = () => {
    if (state && !isLastPurchasedTicket) {
      return state.tno === purchasedTicket.tno;
    }
  };

  useEffect(() => {
    if (currentTickets && !isLastPurchasedTicket) {
      currentTickets.map((ct) => {
        if (ct.tno !== purchasedTicket.tno) {
          setState(undefined);
          setHoveredState(undefined);
        }
        return ct;
      });
    }
  }, [currentTickets, isLastPurchasedTicket, purchasedTicket]);

  // console.log("ticket", currentTickets);

  return (
    // If this div is causing alignment problem in future in some browsers
    // would suggested to be moved up to wrap the table itself and move up the map function
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onBlur={onBlur}
      className={`mb-1 table-border-pre-active rounded-4 ${
        isHoverState() || isFocusState() ? "border-primary " : ""
      }`}
    >
      <tr
        className="mb-1 bg-transparent border-0 w-100"
        onClick={onClick}
        style={{ cursor: "pointer" }}
      >
        <td
          className={`bg-transparent  border-0 fs-13 pt-0 text-light-emphasis ${
            isLastPurchasedTicket ? "pb-4 text-white" : "text-primary"
          }`}
          style={{ width: "86px", cursor: "pointer" }}
        >
          <div className="d-flex flex-column justify-content-center align-items-center">
            <p
              style={{ marginBottom: "10px", cursor: "pointer" }}
              className={`p-0 fs-16 ${
                !isLastPurchasedTicket ? "text-light-emphasis" : "text-primary"
              }`}
            >
              {purchasedTicket.count}
            </p>
          </div>
        </td>
        <td
          className={`bg-transparent border-0 fs-13 td-wrap text-start ${
            !isLastPurchasedTicket ? "text-white" : "text-primary"
          }`}
        >
          <p
            className={`fs-14 mb-0  ${
              !isLastPurchasedTicket ? "text-light-emphasis" : "text-primary"
            }`}
          >
            {/* {selectedStations.from} -&gt; {selectedStations.to} */}
            {purchasedTicket.stid1_name} -&gt; {purchasedTicket.stid2_name}
          </p>
          <p className="mb-1">
            <span>
              {moment(new Date(purchasedTicket.inception)).format("dddd")},{" "}
              {moment(new Date(purchasedTicket.inception)).format("D")}
              <sup>
                {moment(new Date(purchasedTicket.inception))
                  .format("Do")
                  .replace(/\d+/g, "")}
              </sup>{" "}
              {moment(new Date(purchasedTicket.inception)).format("MMMM")}{" "}
              {moment(new Date(purchasedTicket.inception)).format("YYYY")}
            </span>
            {", "}
            {moment(purchasedTicket.rvlist[0].tod1, "HH:mm:ss").format(
              "hh:mm A",
            )}
            {" - "}
            {moment(purchasedTicket.rvlist[0].tod2, "HH:mm:ss").format(
              "hh:mm A ",
            )}
            Train No. {purchasedTicket.rvlist[0].lxid_name},
            <strong
              className={`${
                !isLastPurchasedTicket ? "text-light-emphasis" : "text-primary"
              } text-capitalize`}
            > {purchasedTicket.rvlist[0].seat_data.name}
            </strong>
          </p>
          <p
            className={`${
              isLastPurchasedTicket ? "pb-0 text-primary" : "text-white"
            }`}
          >
            <strong>
              ₦
              {(purchasedTicket.centprice / 100 || 0).toLocaleString(
                navigator.language,
                {
                  minimumFractionDigits: 0,
                },
              )}
              .00
            </strong>
          </p>
        </td>
      </tr>
    </div>
  );
};

export default OrderItem;
